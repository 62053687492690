import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Scene } from 'marzipano';
import { PdfDialogComponent } from '../pdf-dialog/pdf-dialog.component';
import { VideoDialogComponent } from '../video-dialog/video-dialog.component';
import { HotspotService } from '@shared/services/hotspot.service';
import { TransitionService } from '@shared/services/transition-service.service';

@Component({
  selector: 'app-hotspot',
  templateUrl: './hotspot.component.html',
  styleUrls: ['./hotspot.component.scss'],
})
export class HotspotComponent implements OnInit {
  @Input('data') hotspot: any;
  @Input('scene') scene: Scene;
  @Input('sceneId') sceneId: string;
  @Input('viewer') viewer: any;
  @Output() modalRef: BsModalRef;
  @ViewChild('iconEle') iconEle: ElementRef;
  isShowInfo: boolean = false;
  isShowInfoContent: boolean = false;
  expandIcon: boolean = false;

  constructor(
    private modalService: BsModalService,
    private ngZone: NgZone,
    private hotspotService: HotspotService,
    private transitionService : TransitionService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.add();
    setTimeout(() => {
      this.changeListener();
    }, 0);
  }

  add() {
    let that = this;
    // WORKAROUND: Need to wait until hotspots are in the DOM
    this.ngZone.runOutsideAngular(() => {
      setTimeout(function () {
        // Target element
        var element: HTMLElement = document.getElementById(
          that.sceneId + '_' + that.hotspot.slug
        );
        element.style.opacity = '1';

        // Element position
        var position = {
          yaw: that.hotspot.yaw,
          pitch: that.hotspot.pitch,
        };
        that.scene.hotspotContainer().createHotspot(element, position);
      }, 0);
    });
  }

  handleClick() {
    this.hotspotService.onHotspotClick(this.hotspot)
  }

  changeListener() {
    const view = this.viewer.view();
    const regionSize = 800;

    this.viewer.addEventListener('viewChange', (e) => {
      const hotspotScreenPosition = view.coordinatesToScreen({
        yaw: this.hotspot.yaw,
        pitch: this.hotspot.pitch,
      });
      if (
        hotspotScreenPosition &&
        Math.abs(hotspotScreenPosition.x - window.innerWidth / 2) <
          regionSize / 2
      ) {
        this.expandIcon = true;
        return;
      }
      this.expandIcon = false;
    });
  }
}
