<div #tiles id="tiles"></div>
<div class="header-container">
  <img src="assets/logos/company-logo.svg" alt="" />
  South32 - Worsley Alumina Mine Development
</div>
<app-navigation-help-splash
  *ngIf="showNavigationSplash"
  [viewer]="viewer"
></app-navigation-help-splash>

<app-hotspot
  *ngFor="let sceneHotspot of sceneHotspots"
  [scene]="sceneHotspot.scene"
  [data]="sceneHotspot.hotspot"
  [sceneId]="sceneHotspot.sceneId"
  [viewer]="viewer"
></app-hotspot>
<app-side-menu></app-side-menu>
