import { Component, Input } from '@angular/core';
import * as Marzipano from 'marzipano';
import { Viewer } from 'marzipano';

@Component({
  selector: 'app-navigation-help-splash',
  templateUrl: './navigation-help-splash.component.html',
  styleUrls: ['./navigation-help-splash.component.scss'],
})
export class NavigationHelpSplashComponent {
  @Input()
  viewer: Viewer;

  public showNavigationImage: boolean = true;

  constructor() {}

  ngOnChanges(): void {
    if (this.viewer) {
      this.triggerRotationAfterThreeSeconds();
      this.onClickToStopAutoRotaion();
    }
  }

  private triggerRotationAfterThreeSeconds() {
    const autorotate = Marzipano.autorotate({
      yawSpeed: 0.1,
      targetPitch: null,
    });
    setTimeout(() => {
      this.viewer.startMovement(autorotate);
    }, 3000);
  }

  private onClickToStopAutoRotaion() {
    this.viewer._domElement.addEventListener('click', () => {
      this.showNavigationImage = false;
      this.viewer.stopMovement();
    });
  }
}
