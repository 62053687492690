import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { TsLogoComponent } from '@shared/components/ts-logo/ts-logo.component';
import { HotspotComponent } from '@shared/components/hotspot/hotspot.component';
import { SanitizeUrlPipe } from '@shared/pipes/sanitize-url.pipe';
import { AbTestComponent } from '@shared/components/ab-test/ab-test.component';
import { ZoomIndicatorComponent } from '@shared/components/zoom-indicator/zoom-indicator.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfDialogComponent } from './pdf-dialog/pdf-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationHelpSplashComponent } from './navigation-help-splash/navigation-help-splash.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    ModalDialogComponent,
    TsLogoComponent,
    HotspotComponent,
    AbTestComponent,
    SanitizeUrlPipe,
    ZoomIndicatorComponent,
    PdfDialogComponent,
    NavigationHelpSplashComponent,
    SideMenuComponent,
    ImageDialogComponent,
    VideoDialogComponent,
  ],
  exports: [
    ModalDialogComponent,
    TsLogoComponent,
    HotspotComponent,
    AbTestComponent,
    SanitizeUrlPipe,
    ZoomIndicatorComponent,
    NavigationHelpSplashComponent,
    SideMenuComponent,
    ImageDialogComponent,
    VideoDialogComponent,
  ],
  imports: [
    CommonModule,
    LeafletModule,
    AppRoutingModule,
    IvyCarouselModule,
    NgxExtendedPdfViewerModule,
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    SanitizeUrlPipe,
  ],
})
export class SharedModule {}
