export type Hotspot = {
  slug: string;
  title: string;
  type: string;
  yaw: number;
  pitch: number;
  panToYaw: number;
  panToPitch: number;
  panToFov: number;
  fov?: number;
  icon: string;
};

export type HotspotArgs = {
  label: boolean;
};

export enum HotspotType {
  Info = 'info',
  Video = 'video',
  Website = 'website',
  Modal = 'modal',
  Image = 'image'
}
