<ng-container *ngIf="showSideMenu; else menuCircle">
  <div class="menu-container">
    <perfect-scrollbar >
      <div class="side-menu" [@slideInOut] #menuPanel>
        <div class="header fix-height">Virtual Room</div>
        <ng-container *ngFor="let item of menuList">
          <div class="item fix-height" (click)="clickMenuItem(item)">
            <div class="icon-{{ item.icon }} icon-font"></div>
            {{ item.title }}
          </div>
        </ng-container>
      </div>
    </perfect-scrollbar>
  </div>
</ng-container>

<ng-template #menuCircle>
  <div class="menu-circle" (click)="toggleMenu()">
    <img src="assets/images/menu.svg" alt="" />
  </div>
</ng-template>
<div class="overlay" *ngIf="showSideMenu"></div>
