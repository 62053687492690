<div [id]="sceneId + '_' + hotspot?.slug" style="opacity: 0">
  <div class="hotspot-wrapper">
    <div
      class="hotspot-btn"
      [class.grow]="expandIcon"
      id="iconBtn"
      (click)="handleClick()"
    >
      <div class="icon-{{ hotspot.icon }} icon-button"></div>
      {{ hotspot.title }}
    </div>
    <div class="hotspot-icon" #iconEle [class.hide]="expandIcon">
      <div class="icon-{{ hotspot.icon }} icon-font"></div>
    </div>
  </div>
</div>
