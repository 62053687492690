import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AppComponent } from './app.component';
import { ViewpointComponent } from './components/pages/viewpoint/viewpoint.component';
import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SharedModule } from '@shared/components/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AngularSplitModule } from 'angular-split';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AcknowledgementOfCountryComponent } from './components/pages/acknowledgement-of-country/acknowledgement-of-country.component';

@NgModule({
  declarations: [
    AppComponent,
    ViewpointComponent,
    AcknowledgementOfCountryComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    IvyCarouselModule,
    HttpClientModule,
    ModalModule.forRoot(),
    SharedModule,
    AngularSplitModule,
    BrowserAnimationsModule,
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
