import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MenuData } from './menu.data';
import { Viewer } from 'marzipano';
import { Hotspot, HotspotType } from '@shared/models';
import { HotspotService } from '@shared/services/hotspot.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class SideMenuComponent implements OnInit {
  public showSideMenu: boolean = false;
  public menuList: Hotspot[] = MenuData;

  constructor(private hotspotService: HotspotService) {}

  @ViewChild('menuPanel') menuPanel: ElementRef;

  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.menuPanel?.nativeElement?.contains(event.target)) {
      this.showSideMenu = false;
    }
  }

  ngOnInit() {}

  toggleMenu() {
    this.showSideMenu = !this.showSideMenu;
  }

  public clickMenuItem(hotspot: Hotspot) {
    this.hotspotService.onHotspotClick(hotspot)
  }
}
