import { Component, OnInit } from '@angular/core';
import { HotspotService } from '@shared/services/hotspot.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-acknowledgement-of-country',
  templateUrl: './acknowledgement-of-country.component.html',
  styleUrls: ['./acknowledgement-of-country.component.scss'],
})
export class AcknowledgementOfCountryComponent implements OnInit {
  constructor(
    public modalRef: BsModalRef,
    public hotspotService: HotspotService,
  ) {}

  ngOnInit(): void {
    this.hotspotService.isLoadingModal = false;
  }

  public exploreTheRoom() {
    this.modalRef.hide();
  }
}
