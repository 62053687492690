import { Component, OnInit } from '@angular/core';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { HotspotService } from '@shared/services/hotspot.service';

@Component({
  selector: 'ts-pdf-dialog',
  templateUrl: './pdf-dialog.component.html',
  styleUrls: ['./pdf-dialog.component.scss']
})
export class PdfDialogComponent implements OnInit {
  public title: string
  public fileName: string
  public isLoading: boolean = true
  public progress: number

  constructor(
    public modalRef: BsModalRef,
    public hotspotService: HotspotService,
  ) {
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }

  ngOnInit(): void {
    this.hotspotService.isLoadingModal = false;
  }

  onPDFsLoaded = ($event) => {
    this.isLoading = false
  }

  onEvent = ($event) => {
    this.progress = $event.percent
  }

}
