<div class="modal-body">
  <img class="display-image" src='../../../../assets/images/aoc.jpg' />
</div>

<div class="btn-container">
  <p class="description">Explore the Room</p>
  <button class="btn" (click)="exploreTheRoom()">
    <span>Enter</span>
    <i class="fas fa-chevron-right"></i>
  </button>
</div>
