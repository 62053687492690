
import { Component, OnInit } from '@angular/core';
import { HotspotService } from '@shared/services/hotspot.service';
import { BsModalRef } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent implements OnInit {

  public data: any;

  constructor(
    public modalRef: BsModalRef,
    public hotspotService: HotspotService,
  ) { }

  ngOnInit(): void {
    this.hotspotService.isLoadingModal = false;
  }

}
