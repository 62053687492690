import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import * as globals from 'globals';
import { HotspotService } from '@shared/services/hotspot.service';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent {

  ngOnInit(): void {
    this.hotspotService.isLoadingModal = false;
  }

  contactForm = this.formBuilder.group({
    name: '',
    postCode: '',
    contact: '',
    email: '',
    feedback: '',
  });

  errorMessage = '';
  successMessage = '';

  emailServer = globals.emailServer;

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public hotspotService: HotspotService,
  ) {}

  onSubmit(): void {
    console.log("Attempting to send email!");

    try {
      this.http.post<any>(this.emailServer + '/routes/mailrouter',
        {
          name: this.contactForm.value.name,
          postCode: this.contactForm.value.postCode,
          contact: this.contactForm.value.contact,
          email: this.contactForm.value.email,
          feedback: this.contactForm.value.feedback,
        }
      ).subscribe({
        next: data => {
          this.successMessage = 'Form successfully submitted!';
          console.log('Form successfully submitted!', data);
        },
        error: error => {
          this.errorMessage = 'There was an error sending the form!';
          console.error('There was an error sending the form!', error);
        }
      })
      console.log("Successfully sent mail!");
      this.contactForm.reset();
    }
    catch (error) {
      console.log(error);
    }
  }
}
