import { Injectable } from '@angular/core';
import { ImageDialogComponent } from '@shared/components/image-dialog/image-dialog.component';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { PdfDialogComponent } from '@shared/components/pdf-dialog/pdf-dialog.component';
import { VideoDialogComponent } from '@shared/components/video-dialog/video-dialog.component';
import { Hotspot, HotspotType } from '@shared/models';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TransitionService } from './transition-service.service';

@Injectable({
  providedIn: 'root',
})
export class HotspotService {
  isLoadingModal: boolean = false;

  constructor(
    private modalService: BsModalService,
    private transitionService: TransitionService
  ) {}

  public openPDFDialog(hotspot: Hotspot) {
    const initialState = {
      title: hotspot.title,
      fileName: hotspot.slug,
    };
    this.modalService.show(PdfDialogComponent, {
      initialState,
      class: 'modal-xl',
      keyboard: true,
      ignoreBackdropClick: false,
    });
  }

  public openVideoDialog(hotspot: Hotspot) {
    const initialState = {
      data: hotspot,
    };
    setTimeout(() => {
      this.modalService.show(VideoDialogComponent, {
        initialState,
        class: 'modal-xl',
        keyboard: true,
        ignoreBackdropClick: false,
      });
    }, 2000);
  }

  private openImageDialog(hotspot: Hotspot) {
    const initialState = {
      downloadName: hotspot.title,
      fileName: hotspot.slug,
    };
    this.modalService.show(ImageDialogComponent, {
      initialState,
      class: 'image-modal-dialog',
      keyboard: true,
      ignoreBackdropClick: false,
    });
  }

  public openWebsite(hotspot: Hotspot) {
    window.open(hotspot.slug, '_blank', 'noopener,noreferrer');
  }

  public onHotspotClick(hotspot) {
    const basicPosition = {
      yaw: hotspot.yaw,
      pitch: hotspot.pitch,
    };
    
    this.lookToHotspotPosition(basicPosition);

    if (!this.isLoadingModal) {
      this.isLoadingModal = true;
      setTimeout(() => {
        this.openHotSpotModal(hotspot);
      }, 2000);
    }
    
    this.lookToPreviousPosition(basicPosition);
  }

  private lookToHotspotPosition(basicPosition: any) {
    const hotspotPosition = {
      ...basicPosition,
      fov: 0.5,
    };
    setTimeout(() => {
      this.transitionService.lookTo(hotspotPosition);
    });
  }

  private lookToPreviousPosition(basicPosition: any) {
    const previousPosition = {
      ...basicPosition,
      fov: 0.9,
    };
    this.modalService.onHidden.subscribe((resp) => {
      if (resp) {
        this.transitionService.lookTo(previousPosition);
      }
    });
  }

  public openModalDialog(hotspot: Hotspot) {
    this.modalService.show(ModalDialogComponent, {
      class: 'modal-xl',
      keyboard: true,
      ignoreBackdropClick: false,
    });
  }

  public openHotSpotModal(hotspot: Hotspot) {
    if (hotspot.type === HotspotType.Info) {
      this.openPDFDialog(hotspot);
    } else if (hotspot.type === HotspotType.Video) {
      this.openVideoDialog(hotspot);
    } else if (hotspot.type === HotspotType.Modal) {
      this.openModalDialog(hotspot);
    } else if (hotspot.type === HotspotType.Website) {
      this.openWebsite(hotspot);
    } else if (hotspot.type === HotspotType.Image) {
      this.openImageDialog(hotspot);
    }
  }
}
