<section [hidden]="isLoading">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ title }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngx-extended-pdf-viewer
      [src]="'/assets/pdfs/' + fileName + '.pdf'"
      height="80vh"
      [useBrowserLocale]="true"
      [showHandToolButton]="true"
      (pagesLoaded)="onPDFsLoaded($event)"
      (progress)="onEvent($event)"
    ></ngx-extended-pdf-viewer>
  </div>
</section>

<section [hidden]="!isLoading">
  <!-- <progressbar [value]="progress"></progressbar> -->

  <div *ngIf="progress > 0; else loading">
    <h6>{{ progress | number: "1.0-0" }} %</h6>
  </div>

  <ng-template #loading>
    <h6>Loading PDF...</h6>
  </ng-template>
</section>
