<div class="modal-body">
  <img class="display-image" [src]="'/assets/images/' + fileName + '.jpg'" />
</div>

<div class="tool-bar">
  <a [href]="'/assets/images/' + fileName + '.jpg'" download>
    <img class="icon-circle" src="../../../../assets/icons/download.svg" />
  </a>

  <img
    class="icon-circle"
    src="../../../../assets/icons/close.svg"
    (click)="modalRef.hide()"
  />
</div>
